import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Input, Divider, Table, Empty } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Quantity from "../../../assets/images/weigher.png";
import Sales from "../../../assets/images/sales.png";
import Offers from "../../../assets/images/offers.png";
import Stock from "../../../assets/images/stock-white.png";
import Info from "../../../assets/images/Info.png";
import Aclose from "../../../assets/images/close.png";
import ok from "../../../assets/images/tick.svg";
import Backspace from "../../../assets/images/backspac.png";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import "../../style.css";
import AspectRatio from "react-aspect-ratio";
import DiscountImg from "../../../assets/images/discountQty.png";
import StockImg2 from "../../../assets/images/home.png";
import DownArrowGreen from "../../../assets/images/downArrowGreen.svg";
import DownArrowRed from "../../../assets/images/downArrowRed.svg";
import UpArrowGreen from "../../../assets/images/upArrowGreen.svg";
import CircleCloseIcon from "../../../assets/images/circle-close-icon.svg";
import data from "../../../constants/retailPos.json";
import Axios from "axios";
import { serverUrl } from "../../../constants/config";
import _ from "lodash";

const QuantityUpdate = (props) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const { selectedProductInCart, addProduct, setIsQtyUpdate } = props;
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [weight, setWeight] = useState("");

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleWeight = (value) => {
    if (weight === "" && value === "x") {
      setWeight("");
    } else if (value === "x") {
      setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    } else {
      setWeight(`${weight}${value}`);
    }
  };

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
  }, [selectedProductInCart]);

  const weightRef = useRef("");
  useEffect(() => {
    weightRef.current = weight;
  }, [weight]);

  const addQuantity = (value) => {
    let newWeight = value === "keycodeCal" ? weightRef.current : weight;
    let newSelectedProduct = value === "keycodeCal" ? selectedProductInCartRef.current : selectedProductInCart;
    const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    if (validation.test(parseFloat(newWeight))) {
      const netWeight = parseFloat(newWeight) - parseFloat(newSelectedProduct.weight);
      if (!newSelectedProduct.isReturn) {
        addProduct(newSelectedProduct, netWeight);
      }
      setIsQtyUpdate(false);
    } else {
      console.warn("Invalid input", weight);
    }
  };

  const infoCardRef = useRef();

  const focusFirstElement = () => {
    setTimeout(() => {
      infoCardRef.current.focus();
    }, 100);
  };

  useEffect(() => {
    focusFirstElement();
  }, []);

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      addQuantity("keycodeCal");
    }

    if (keyCode === 27) {
      setIsQtyUpdate(false);
    }

    if (keyCode === 39) {
      infoCardRef.current.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const full = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  };

  const four = {
    xs: { span: 4 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 4 },
    xl: { span: 4 },
    xxl: { span: 4 },
  };

  const half = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  };

  const twenty = {
    xs: { span: 20 },
    sm: { span: 20 },
    md: { span: 20 },
    lg: { span: 20 },
    xl: { span: 20 },
    xxl: { span: 20 },
  };

  const [offersList, setOffersList] = useState([]);

  const [stockList, setStockList] = useState([]);

  const [salesInfo, setSalesInfo] = useState({});

  const [selectedOption, setSelectedOption] = useState("Quantity");

  const productInfoData = [
    {
      title: "Quantity",
      imgSrc: Quantity,
    },
    {
      title: "Offers",
      imgSrc: Offers,
    },
    {
      title: "Stock",
      imgSrc: Stock,
    },
    {
      title: "Sales",
      imgSrc: Sales,
    },
    {
      title: "Info",
      imgSrc: Info,
    },
  ];

  const handleProductInfo = (record) => {
    setSelectedOption(record);
  };

  const manualWeightInputRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      manualWeightInputRef?.current.focus();
    }, 100);
  }, []);

  // Product Info Services //

  useEffect(() => {
    const offersInputValues = {
      query: `query{
        getProductOffers(productId:"${selectedProductInCart.productId}"){
          name
        }  
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: offersInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { getProductOffers } = response.data.data;
      setOffersList([...getProductOffers]);
    });
  }, []);

  useEffect(() => {
    const stockInputValues = {
      query: `query{     getProductsNearbyStock(bUnitId:"${tillData.tillAccess.csBunit.csBunitId}" code:"${selectedProductInCart.value}"){         csBunitId         value         name         stores{             csNearbyStoreId             name             nearbybUnitId             viewStock             isIssue             warehouse{                 mWarehouseID                 name                 value                 storageBin{                     mStoragebinID                     name                     stocks{                         mStockID                         qtyonhand                         product{                             mProductId                             value                             name                             upc                             uom{                                 csUomId                                 name                             }                             productCategory{                                 mProductCategoryId                                 value                                 name                             }                         }                         batch{                             mBatchId                             batchno                             upc                             price                             startdate                             enddate                             life                         }                      }                     totalQty                 }             }         }     } }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: stockInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      console.log("response", response);
      const { stores } = response.data.data.getProductsNearbyStock;
      setStockList([...stores]);
    });
  }, []);

  useEffect(() => {
    const salesInputValues = {
      query: `query{     getProductSales(bUnitId:"${tillData.tillAccess.csBunit.csBunitId}", code:"${selectedProductInCart.value}"){         cSBunitID         cSClientID         product{             mProductId             value             name             upc             csUomId             uomName             mProductCategoryId             weekSale             weekSaleQty             twoweekSale             twoWeekSaleQty             fourWeekSale             fourWeekSaleQty             yearSale             yearSaleQty           }     } }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: salesInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      console.log("response", response);
      const { getProductSales } = response.data.data;
      setSalesInfo({...getProductSales});
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col span={12}>
          <p style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Product Details</p>
        </Col>
        <Col span={12}>
          <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setIsQtyUpdate(false)} />
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: data.posScreenStyles.quantityUpdateCard.marginBottom,
        }}
      >
        <Col {...full}>
          <Row gutter={[0, 10]}>
            <Col {...full}>
              <Card bodyStyle={{ padding: 10 }} className="productInfoCard" style={data.posScreenStyles.quantityUpdateCard.productInfoCard}>
                <Row>
                  <Col {...four}>
                    <img style={data.posScreenStyles.quantityUpdateCard.productImg} onError={setDefaultImage} src={selectedProductInCart.imageurl} alt="" />
                  </Col>
                  <Col {...twenty}>
                    <Row>
                      <Col {...full}>
                        <Row>
                          <label>
                            <span style={data.posScreenStyles.quantityUpdateCard.prodNameDetails}>{selectedProductInCart.name}</span>
                          </label>
                        </Row>
                        <Row>
                          <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLbl}>
                            &#8377;&nbsp;
                            <span>{selectedProductInCart.salePrice}</span>
                          </label>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <p style={data.posScreenStyles.quantityUpdateCard.prodDescLbl} className="prod-desc-lbl">
                          {selectedProductInCart.description}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <InfoCircleFilled style={data.posScreenStyles.quantityUpdateCard.infoCircleIcon} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {selectedOption === "Quantity" && (
          <Col span={14} style={{ paddingRight: "1.5%" }}>
            <Card bodyStyle={{ padding: 0 }} className="prodQtyCard" style={data.posScreenStyles.quantityUpdateCard.prodQtyCard}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    ref={manualWeightInputRef}
                    size="large"
                    value={weight}
                    onChange={onChangeWeight}
                    allowClear={false}
                    style={data.posScreenStyles.quantityUpdateCard.quantityUpdateInput}
                  />
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("1")}>
                      1
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("2")}>
                      2
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("3")}>
                      3
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => setIsQtyUpdate(false)}>
                      <img src={Aclose} style={data.posScreenStyles.quantityUpdateCard.qtyBtnClose} alt="close icon" />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("4")}>
                      4
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("5")}>
                      5
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("6")}>
                      6
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("x")}>
                      <img src={Backspace} alt="back space" style={data.posScreenStyles.quantityUpdateCard.qtyBtnBack} />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("7")}>
                          7
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("8")}>
                          8
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("9")}>
                          9
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={16}>
                      <button className="quantity-btn-zero" style={data.posScreenStyles.quantityUpdateCard.quantityBtnZero} onClick={() => handleWeight("0")}>
                        0
                      </button>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight(".")}>
                          .
                        </button>
                      </AspectRatio>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[16, 16]} style={{ height: "100%" }}>
                    <Col span={24}>
                      <button style={data.posScreenStyles.quantityUpdateCard.quantityBtnOk} onClick={() => addQuantity()}>
                        {/* <AspectRatio ratio="1"> */}
                        <img src={ok} alt="ok" width={40} />
                        {/* </AspectRatio> */}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {selectedOption === "Offers" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Offers</span>}>
              {offersList.length > 0 ? (
                <Scrollbars autoHide className="offersCardList">
                  <div style={data.posScreenStyles.quantityUpdateCard.offersDiv}>
                    {offersList.map((item) => (
                      <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.offersCardStyles}>
                        <Row>
                          <Col span={4}>
                            <img src={DiscountImg} alt="discount" />
                          </Col>
                          <Col span={20} style={{ alignSelf: "center" }}>
                            {/* <Row>
                            <h3 style={data.posScreenStyles.quantityUpdateCard.offersTitle}>{item.name}</h3>
                          </Row> */}
                            <Row>
                              <h3 style={data.posScreenStyles.quantityUpdateCard.offersOffer}>{item.name}</h3>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </div>
                </Scrollbars>
              ) : (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span
                      style={{
                        color: "#00000040",
                        fontWeight: 600,
                      }}
                    >
                      No Offers
                    </span>
                  }
                  style={{ paddingTop: 70 }}
                ></Empty>
              )}
            </Card>
          </Col>
        )}
        {selectedOption === "Stock" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Store Stock</span>}>
              <div style={data.posScreenStyles.quantityUpdateCard.offersDiv}>
                <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.stockCardStyles}>
                  <Row>
                    <Col span={20}>
                      <Row>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Quantity</p>
                      </Row>
                      <Row>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>{_.sumBy(stockList, "warehouse.storageBin.totalQty") || 0}</p>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <div>
                        <img src={StockImg2} alt="stock" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Divider style={data.posScreenStyles.cartTable.divider} />
                <h3 style={data.posScreenStyles.quantityUpdateCard.nearbyStock}>Nearby Store Stocks</h3>
                <Divider style={data.posScreenStyles.cartTable.divider} />
                <Table
                  style={data.posScreenStyles.quantityUpdateCard.stockTable}
                  size="small"
                  pagination={false}
                  dataSource={[...stockList]}
                  columns={[
                    {
                      title: "Store",
                      dataIndex: "store",
                      key: "store",
                      render: (text, record, index) => (
                        <div>
                          {record.name || ""}
                        </div>
                      ),
                    },
                    {
                      title: "In Stock",
                      dataIndex: "stock",
                      key: "stock",
                      render: (text, record, index) => (
                        <div>
                          {record.warehouse.storageBin.totalQty || 0}
                        </div>
                      ),
                      align: "right",
                    },
                  ]}
                />
              </div>
            </Card>
          </Col>
        )}
        {selectedOption === "Sales" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Sales</span>}>
              <Scrollbars autoHide className="infoCardList">
                <Row gutter={[0, 12]} style={data.posScreenStyles.quantityUpdateCard.salesRow}>
                  <Col span={24}>
                    <Card bodyStyle={{ padding: "5px 10px" }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Weekly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>&#8377;{salesInfo.product.weekSale || 0}</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentNegValue}>- 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowRed} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bodyStyle={{ padding: "5px 10px" }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Two Weeks Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>&#8377;{salesInfo.product.twoweekSale || 0}</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 15%</p>
                        </Col>
                        <Col span={4}>
                          <img src={UpArrowGreen} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 10 }}>
                    <Card bodyStyle={{ padding: "5px 10px" }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Monthly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>&#8377;{salesInfo.product.fourWeekSale || 0}</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentNegValue}>- 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowRed} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bodyStyle={{ padding: "5px 10px" }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>&#8377;{salesInfo.product.yearSale || 0}</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowGreen} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 10 }} />
                  {/* <Col span={12}>
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}
                      >
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$3678M</p>
                        <Row>
                          <Col span={20}>
                            <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                          </Col>
                          <Col span={4}>
                            <img
                              src={DownArrowGreen}
                              alt="down arrow image"
                              style={{float: data.posScreenStyles.quantityUpdateCard.float}}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 10 }}>
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}
                      >
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$3678M</p>
                        <Row>
                          <Col span={20}>
                            <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                          </Col>
                          <Col span={4}>
                            <img
                              src={DownArrowGreen}
                              alt="down arrow image"
                              style={{float: data.posScreenStyles.quantityUpdateCard.float}}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col> */}
                </Row>
              </Scrollbars>
            </Card>
          </Col>
        )}
        {selectedOption === "Info" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Product Information</span>}>
              <Scrollbars autoHide className="infoCardList">
                <div style={{ padding: 10 }}>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Description</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>
                    {selectedProductInCart.description}
                  </p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Shelf Life</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>{selectedProductInCart.shelfLife ? `${selectedProductInCart.shelfLife} months` : ""}</p>
                </div>
              </Scrollbars>
            </Card>
          </Col>
        )}
        <Col span={10} style={{ boxShadow: "rgb(0 0 0 / 16%) 0px 2px 4px", borderRadius: "6px" }}>
          {productInfoData.map((item, index) => (
            <Card
              key={index + 1}
              onClick={() => handleProductInfo(item.title)}
              ref={index === 0 ? infoCardRef : null}
              tabIndex={0}
              onKeyPress={(e) => (e.charCode === 13 ? handleProductInfo(item.title) : null)}
              onBlur={() => (index === productInfoData.length - 1 ? focusFirstElement() : null)}
              className="qty-card"
              style={item.title === selectedOption ? data.posScreenStyles.quantityUpdateCard.qtyInfoCardSelected : data.posScreenStyles.quantityUpdateCard.qtyInfoCard}
              bodyStyle={{ padding: 10 }}
            >
              <Row style={data.posScreenStyles.quantityUpdateCard.qtyCardRow}>
                <Col {...half}>
                  <img src={item.imgSrc} alt="" className="qty-img" />
                </Col>
                <Col {...half} className="padding-ten">
                  <label className="qty-lbl">{item.title}</label>
                </Col>
              </Row>
            </Card>
          ))}
          <span tabIndex={0}></span>
        </Col>
      </Row>
    </div>
  );
};

export default QuantityUpdate;
