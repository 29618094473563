import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Card, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Backspace from "../../../assets/images/backspac.png";
import db from "../../../database";
import "react-aspect-ratio/aspect-ratio.css";
import { useWindowDimensions } from "../../../lib/useViewport";
import data from "../../../constants/retailPos.json";

const AddProduct = (props) => {
  const { width } = useWindowDimensions();
  const { checkIsManualWeight } = props;
  const [dPadKey, setDpadKey] = useState("");
  const handleProductIdByDpad = (value) => {
    if (dPadKey === "" && value === "x") {
      setDpadKey("");
    } else if (value === "x") {
      setDpadKey((prevDpadKey) => `${prevDpadKey.toString().substring(0, prevDpadKey.toString().length - 1)}`);
    } else {
      setDpadKey((prevDPadKey) => `${prevDPadKey}${value}`);
    }
  };

  const dPadKeyInput = (event) => {
    setDpadKey(event.target.value);
  };

  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 27) {
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const getProductByDpadKey = (type) => {
    if (dPadKey !== "" && dPadKey !== "." && dPadKey !== undefined) {
      const validation = RegExp("^.{1,128}$");
      if (validation.test(dPadKey)) {
        db.products
          .where(type)
          .equalsIgnoreCase(dPadKey)
          .or(type === "upc" && "upcIndex")
          .equalsIgnoreCase(dPadKey)
          .toArray()
          .then((product) => {
            if (product.length > 0) {
              const obj = { ...product[0] };
              if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                if (obj.mBatch.length === 1) {
                  addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
                } else {
                  // let upcKeys = 0;
                  const productSet = [];
                  const localObj = obj;
                  for (let i = 0; i < obj.mBatch.length; i += 1) {
                    const batchItem = { ...localObj.mBatch[i] };
                    const obj = { ...localObj };
                    if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                      // prettier-ignore
                      const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                      const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                      batchItem.price = taxedPrice;
                      obj.cTaxId = obj.contraTaxId;
                      obj.taxRate = obj.contraRate;
                    }
                    const productDefined = {
                      batchno: batchItem.batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isPromoApplicable: obj.isPromoApplicable,
                      isReturn: false,
                      mBatchId: batchItem.mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: batchItem.price,
                      returnQty: null,
                      salePrice: batchItem.price,
                      mrpPrice: batchItem.listPrice,
                      stock: obj.onhandQty,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      upc: batchItem.upc,
                      value: obj.value,
                      weight: 0,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                      csBunitId: obj.csBunitId,
                      mProductCategoryId: obj.mProductCategoryId,
                      productManufacturerId: obj.productManufacturerId,
                      productBrandId: obj.productBrandId,
                      batchedProduct: obj.batchedProduct,
                      batchedForSale: obj.batchedForSale,
                      batchedForStock: obj.batchedForStock,
                      multiPrice: obj.multiPrice,
                      shelfLife: obj.shelfLife,
                    };
                    //if (batchItem.upc.toLowerCase() === dPadKey.toLowerCase()) upcKeys += 1;
                    productSet.push(productDefined);
                  }
                  setBatchSetAvailable([...productSet]);
                  setDisplayBatchSelection(true);
                }
              } else {
                addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
              }
            } else {
              console.warn("No Products Found:", type, dPadKey);
            }
          });
      } else {
        console.warn("Invalid Input:", dPadKey);
      }
    } else {
      console.warn("Invalid Input:", dPadKey);
    }
  };

  const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
      shelfLife: productObj.shelfLife,
    };
    checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };

  return (
    <div>
      <Row gutter={width >= 1920 ? [24, 24] : [0, 8]} justify="center">
        <Col span={16} justify="space-around">
          <Input
            size="large"
            allowClear={false}
            value={dPadKey}
            onChange={dPadKeyInput}
            style={data.posScreenStyles.addproductStyles.addProductInput}
            className="addProductCodeInput"
          />
        </Col>
        <Col span={16} justify="space-around">
          <Row gutter={width >= 1600 ? [20, 20] : [0, 12]}>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("1")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                1
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("2")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                2
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("3")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                3
              </button>
            </Col>
            <Col span={6}>
              <button className="addBySKUbtn" style={data.posScreenStyles.addproductStyles.addBySKUUPCBtn} onClick={() => getProductByDpadKey("value")}>
                <div style={{ display: "inline-grid" }}>
                  <span>ADD</span>
                  <span style={data.posScreenStyles.addproductStyles.addByText}>By SKU</span>
                </div>
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("4")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                4
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("5")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                5
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("6")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                6
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => getProductByDpadKey("upc")} className="addByUPCbtn" style={data.posScreenStyles.addproductStyles.addBySKUUPCBtn}>
                <div style={{ display: "inline-grid" }}>
                  <span>ADD</span>
                  <span style={data.posScreenStyles.addproductStyles.addByText}>By UPC</span>
                </div>
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("7")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                7
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("8")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                8
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("9")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                9
              </button>
            </Col>
            <Col span={6}>
              <button className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}></button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad(".")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                .
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("0")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                0
              </button>
            </Col>
            <Col span={6}>
              <button onClick={() => handleProductIdByDpad("x")} className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}>
                <img src={Backspace} alt="back space" style={data.posScreenStyles.addproductStyles.addprodback} />
              </button>
            </Col>
            <Col span={6}>
              <button className="dialer-btn" style={data.posScreenStyles.addproductStyles.dialerBtn}></button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]} justify="center">
            <Col span={12}>
              <Button size="large" className="add-product-save" style={data.posScreenStyles.addproductStyles.addProductBtns} onClick={() => props.parkBill()}>
                <Row>
                  <Col span={24}>
                    <label>SAVE</label>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button size="large" className="add-product-pay" style={data.posScreenStyles.addproductStyles.addProductPayBtn} onClick={() => props.openPaymentModal()}>
                <Row>
                  <Col span={24}>
                    <label>PAY</label>
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} keyboard={true} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {batchSetAvailable.map((item, index) => (
                <Card
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                  onKeyPress={(e) => (e.charCode === 13 ? selectProductToCart(item) : null)}
                  className="focusDashboardCard"
                  tabIndex={0}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Mrp</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>&#8377; {parseFloat(item.mrpPrice).toFixed(2)}</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                    </Col> */}
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </div>
  );
};

export default AddProduct;
