import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Scrollbars } from "react-custom-scrollbars";
import DefaultProductImage from "../../../assets/images/no-image.svg";
import "../../style.css";
import data from "../../../constants/retailPos.json";
import locale from "antd/lib/date-picker/locale/en_US";

const ProductPanel = (props) => {
  const { checkIsManualWeight, productsList, isProductsFilter, getMoreProducts, productListCardRef } = props;
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
      if (obj.mBatch.length === 1) {
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        const localObj = obj;
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = { ...localObj.mBatch[i] };
          const obj = { ...localObj };
          if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
            // prettier-ignore
            const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
            const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
            batchItem.price = taxedPrice;
            obj.cTaxId = obj.contraTaxId;
            obj.taxRate = obj.contraRate;
          }
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description,
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl,
            isDecimal: obj.isDecimal,
            isManualQty: obj.isManualQty,
            isPromoApplicable: obj.isPromoApplicable,
            isReturn: false,
            mBatchId: batchItem.mBatchId,
            mPricingruleId: null,
            name: obj.name,
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId,
            realPrice: batchItem.price,
            returnQty: null,
            salePrice: batchItem.price,
            mrpPrice: batchItem.listPrice,
            stock: obj.onhandQty,
            tax: obj.cTaxId,
            taxAmount: 0,
            taxRate: obj.taxRate,
            uom: obj.csUomId,
            uom_name: obj.uomName,
            upc: batchItem.upc,
            value: obj.value,
            weight: 0,
            shortDescription: obj.shortDescription,
            hsncode: obj.hsncode,
            csBunitId: obj.csBunitId,
            mProductCategoryId: obj.mProductCategoryId,
            productManufacturerId: obj.productManufacturerId,
            productBrandId: obj.productBrandId,
            batchedProduct: obj.batchedProduct,
            batchedForSale: obj.batchedForSale,
            batchedForStock: obj.batchedForStock,
            multiPrice: obj.multiPrice,
            shelfLife: obj.shelfLife
          };
          productSet.push(productDefined);
        }
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
      shelfLife: productObj.shelfLife
    };
    checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };

  let cardDetails = {
    width: props.productsList.length === 1 ? "15vw" : "100%",
    height: data.posScreenStyles.productsCard.cardDetails.height,
    background: data.posScreenStyles.productsCard.cardDetails.background,
    borderRadius: data.posScreenStyles.productsCard.cardDetails.borderRadius,
    border: data.posScreenStyles.productsCard.cardDetails.border,
  };

  const col = [];
  const productsCount = productsList.length;

  const focusFirstElement = (i) => {
    /* if (i + 1 === productsCount && productsCount !== 1) {
      setTimeout(() => {
        if (productListCardRef.current) {
          productListCardRef.current.focus();
        }
      }, 100);
    } */
  };

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 27) {
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  for (let i = 0; i < productsCount; i += 1) {
    const obj = productsList[i];
    col.push(
      <Col key={`${obj.mProductId}${obj.name}`} span={isProductsFilter ? 12 : 8}>
        {posConfig.showImage === "Y" ? (
          <Card
            ref={i === 0 ? productListCardRef : null}
            id={`productCardItem-${i}`}
            onBlur={() => focusFirstElement(i)}
            tabIndex={0}
            className="focusDashboardCard"
            bodyStyle={{ padding: 10 }}
            onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
            onClick={() => pickProduct(obj)}
            style={cardDetails}
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  onError={setDefaultImage}
                  style={{
                    height: props.productsList.length === 2 ? "80px" : "130px",
                    width: props.productsList.length === 2 ? "80px" : "130px",
                    marginTop: "15%",
                  }}
                  src={obj.imageurl || DefaultProductImage}
                  alt=""
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col span={24}>
                <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                  &#8377;&nbsp;<span>{obj.sunitprice}</span>
                </label>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label style={data.posScreenStyles.productsCard.cardDetails.prodName}>{obj["name"]}</label>
              </Col>
            </Row>
          </Card>
        ) : (
          <Card
            ref={i === 0 ? productListCardRef : null}
            id={`productCardItem-${i}`}
            onBlur={() => focusFirstElement(i)}
            tabIndex={0}
            className="focusDashboardCard prodCardNoImage"
            bodyStyle={{ padding: "5px 10px" }}
            onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
            onClick={() => pickProduct(obj)}
          >
            <Row>
              <Col span={24}>
                <p className="prodNameNoImg">{obj["name"]}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="productPriceNoImg">
                  &#8377;&nbsp;<span>{obj.sunitprice}</span>
                </p>
              </Col>
            </Row>
          </Card>
        )}
      </Col>
    );
  }
  return (
    <React.Fragment>
      <Scrollbars autoHide className="productScroll">
        <Row>
          <InfiniteScroll
            dataLength={productsCount}
            next={() => getMoreProducts()}
            hasMore={true}
            endMessage={
              <p className="center">
                <b>Yay! you have seen it all</b>
              </p>
            }
            style={{ overflowX: "hidden" }}
          >
            <Row style={{ paddingRight: props.isProductsFilter ? "10px" : 0 }} gutter={[4, 4]}>
              {col.map((element) => element)}
              <span tabIndex={0}></span>
            </Row>
          </InfiniteScroll>
        </Row>
      </Scrollbars>

      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} keyboard={false} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {batchSetAvailable.map((item) => (
                <Card
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                  onKeyPress={(e) => (e.charCode === 13 ? selectProductToCart(item) : null)}
                  className="focusDashboardCard"
                  tabIndex={0}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Mrp</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>&#8377; {parseFloat(item.mrpPrice).toFixed(2)}</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                    </Col> */}
                  </Row>
                </Card>
              ))}
              {/* <span tabIndex={0}></span> */}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </React.Fragment>
  );
};

export default ProductPanel;
